import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/css/css/src/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "table"
    }}>{`Table`}</h1>
    <p>{`To usa table just add `}<inlineCode parentName="p">{`.table`}</inlineCode>{` on `}<inlineCode parentName="p">{`<table>`}</inlineCode>{` tag.
The `}<inlineCode parentName="p">{`<th>`}</inlineCode>{` tag has a default border. Use colors border classes to change or overwrite with css on `}<inlineCode parentName="p">{`<th>`}</inlineCode>{` tag`}</p>
    <h2 {...{
      "id": "exemple"
    }}>{`Exemple`}</h2>
    <table className="table">
  <thead>
    <tr>
      <th scope="col" className='border-pink'>#</th>
      <th scope="col" className='border-pink'>Column 1</th>
      <th scope="col" className='border-pink last-col'>Column 2</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>
        1
      </td>
      <td>
        Column 2
      </td>
      <td>
        Column 3
      </td>
    </tr>
    <tr>
      <td>
        2
      </td>
      <td>
        Column 2
      </td>
      <td>
        Column 3
      </td>
    </tr>
    <tr>
      <td>
        3
      </td>
      <td>
        Column 2
      </td>
      <td>
        Column 3
      </td>
    </tr>
  </tbody>
    </table>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<table class="table table-responsive">
  <thead>
    <tr>
      <th scope="col" class='border-pink'>#</th>
      <th scope="col" class='border-pink'>Column 1</th>
      <th scope="col" class='border-pink last-col'>Column 2</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>
        1
      </td>
      <td>
        Column 2
      </td>
      <td>
        Column 3
      </td>
    </tr>
    <tr>
      <td>
        2
      </td>
      <td>
        Column 2
      </td>
      <td>
        Column 3
      </td>
    </tr>
    <tr>
      <td>
        3
      </td>
      <td>
        Column 2
      </td>
      <td>
        Column 3
      </td>
    </tr>
  </tbody>
</table>
`}</code></pre>
    <h3 {...{
      "id": "responsive-overflow"
    }}>{`Responsive Overflow`}</h3>
    <p>{`With the `}<inlineCode parentName="p">{`.table-overflow`}</inlineCode>{` class on parent container if the width of the parent element is smaller than the table's contents, it will generate a horizontal overflow`}</p>
    <div className='table-overflow'>
  <table className="table">
    <thead>
      <tr>
        <th scope="col" className='border-pink'>#</th>
        <th scope="col" className='border-pink'>Column 1</th>
        <th scope="col" className='border-pink last-col'>Column 2</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>
          1
        </td>
        <td>
          <p style={{
                "minWidth": "700px"
              }}>
            Column 2
          </p>
        </td>
        <td>
          Column 3
        </td>
      </tr>
      <tr>
        <td>
          2
        </td>
        <td>
          <p style={{
                "minWidth": "700px"
              }}>
            Column 2
          </p>
        </td>
        <td>
          Column 3
        </td>
      </tr>
      <tr>
        <td>
          3
        </td>
        <td>
          <p style={{
                "minWidth": "700px"
              }}>
            Column 2
          </p>
        </td>
        <td>
          Column 3
        </td>
      </tr>
    </tbody>
  </table>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class='table-overflow'>
  <table class="table table-overflow">
    ...
  </table>
</div>
`}</code></pre>
    <h3 {...{
      "id": "mobile-table"
    }}>{`Mobile Table`}</h3>
    <p>{`The table using the `}<inlineCode parentName="p">{`.table-responsive`}</inlineCode>{` class on `}<inlineCode parentName="p">{`lg`}</inlineCode>{` breaktpoint (< 992px) makes each row of the table have its respective column inverted per row since the `}<inlineCode parentName="p">{`<td>`}</inlineCode>{` has the attribute `}<inlineCode parentName="p">{`data-label`}</inlineCode>{` filled`}</p>
    <table className="table table-responsive">
  <thead>
    <tr>
      <th scope="col" className='border-pink'>#</th>
      <th scope="col" className='border-pink'>Column 1</th>
      <th scope="col" className='border-pink last-col'>Column 2</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td data-label='Column 1'>
        1
      </td>
      <td data-label='Column 2'>
        <p className='text-ellipsis'>
          Column 2
        </p>
      </td>
      <td data-label='Column 3'>
        <p className='text-ellipsis'>
          Column 3
        </p>
      </td>
    </tr>
    <tr>
      <td data-label='Column 1'>
        2
      </td>
      <td data-label='Column 2'>
        <p className='text-ellipsis'>
          Column 2
        </p>
      </td>
      <td data-label='Column 3'>
        <p className='text-ellipsis'>
          Column 3
        </p>
      </td>
    </tr>
    <tr>
      <td data-label='Column 1'>
        3
      </td>
      <td data-label='Column 2'>
        <p className='text-ellipsis'>
          Column 2
        </p>
      </td>
      <td data-label='Column 3'>
        <p className='text-ellipsis'>
          Column 3
        </p>
      </td>
    </tr>
  </tbody>
    </table>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<table class="table table-responsive">
  ...
  <tr>
    <td data-label='Column 1'>
      1
    </td>
    <td data-label='Column 2'>
      <p
        class='text-ellipsis'
      >
        Column 2
      </p>
    </td>
    <td data-label='Column 3'>
      <p
        class='text-ellipsis'
      >
        Column 3
      </p>
    </td>
  </tr>
</table>
`}</code></pre>
    <h3 {...{
      "id": "buttons-hover"
    }}>{`Buttons Hover`}</h3>
    <p>{`To use the hover in the table row to show the buttons create a column with the `}<inlineCode parentName="p">{`.wrapper-buttons`}</inlineCode>{` class.`}</p>
    <table className="table table-responsive">
  <thead>
    <tr>
      <th scope="col" className='border-pink'>#</th>
      <th scope="col" className='border-pink'>Column 1</th>
      <th scope="col" className='border-pink last-col'>Column 2</th>
      <th scope="col" className='border-pink display-lg-none'>Actions</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td data-label='Column 1'>
        1
      </td>
      <td data-label='Column 2'>
        <p className='text-ellipsis'>
          Column 2
        </p>
      </td>
      <td data-label='Column 3'>
        <p className='text-ellipsis'>
          Column 3
        </p>
      </td>
      <td className='wrapper-buttons' data-label='Actions'>
        <button className='button rounded button-gray border-transparent inverted p-1 mx-1' style={{
              "fontSize": "12px",
              "width": "30px",
              "height": "30px"
            }}>
          <i className="fas fa-play"></i>
        </button>
        <button className='button rounded button-gray border-transparent inverted p-1 mx-1' style={{
              "fontSize": "12px",
              "width": "30px",
              "height": "30px"
            }}>
          <i className="fas fa-edit"></i>
        </button>
        <button className='button rounded button-gray border-transparent inverted p-1 mx-1' style={{
              "fontSize": "12px",
              "width": "30px",
              "height": "30px"
            }}>
          <i className="fas fa-dollar-sign"></i>
        </button>
        <button className='button rounded button-gray border-transparent inverted p-1 mx-1' style={{
              "fontSize": "12px",
              "width": "30px",
              "height": "30px"
            }}>
          <i className="fas fa-trash"></i>
        </button>
      </td>
    </tr>
    <tr>
      <td data-label='Column 1'>
        2
      </td>
      <td data-label='Column 2'>
        <p className='text-ellipsis'>
          Column 2
        </p>
      </td>
      <td data-label='Column 3'>
        <p className='text-ellipsis'>
          Column 3
        </p>
      </td>
      <td className='wrapper-buttons' data-label='Actions'>
        <button className='button rounded button-gray border-transparent inverted p-1 mx-1' style={{
              "fontSize": "12px",
              "width": "30px",
              "height": "30px"
            }}>
          <i className="fas fa-play"></i>
        </button>
        <button className='button rounded button-gray border-transparent inverted p-1 mx-1' style={{
              "fontSize": "12px",
              "width": "30px",
              "height": "30px"
            }}>
          <i className="fas fa-edit"></i>
        </button>
        <button className='button rounded button-gray border-transparent inverted p-1 mx-1' style={{
              "fontSize": "12px",
              "width": "30px",
              "height": "30px"
            }}>
          <i className="fas fa-dollar-sign"></i>
        </button>
        <button className='button rounded button-gray border-transparent inverted p-1 mx-1' style={{
              "fontSize": "12px",
              "width": "30px",
              "height": "30px"
            }}>
          <i className="fas fa-trash"></i>
        </button>
      </td>
    </tr>
    <tr>
      <td data-label='Column 1'>
        3
      </td>
      <td data-label='Column 2'>
        <p className='text-ellipsis'>
          Column 2
        </p>
      </td>
      <td data-label='Column 3'>
        <p className='text-ellipsis'>
          Column 3
        </p>
      </td>
      <td className='wrapper-buttons' data-label='Actions'>
        <button className='button rounded button-gray border-transparent inverted p-1 mx-1' style={{
              "fontSize": "12px",
              "width": "30px",
              "height": "30px"
            }}>
          <i className="fas fa-play"></i>
        </button>
        <button className='button rounded button-gray border-transparent inverted p-1 mx-1' style={{
              "fontSize": "12px",
              "width": "30px",
              "height": "30px"
            }}>
          <i className="fas fa-edit"></i>
        </button>
        <button className='button rounded button-gray border-transparent inverted p-1 mx-1' style={{
              "fontSize": "12px",
              "width": "30px",
              "height": "30px"
            }}>
          <i className="fas fa-dollar-sign"></i>
        </button>
        <button className='button rounded button-gray border-transparent inverted p-1 mx-1' style={{
              "fontSize": "12px",
              "width": "30px",
              "height": "30px"
            }}>
          <i className="fas fa-trash"></i>
        </button>
      </td>
    </tr>
  </tbody>
    </table>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<table class="table">
  ...
  <tbody>
    ...
      <td class='wrapper-buttons' data-label='Actions'>
       <!-- Bottons or other elements here -->
      </td>
  ...
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      